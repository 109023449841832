import { Box } from '@mentimeter/ragnar-ui';
import { Narrow, Section } from '../layout';
import { Motion } from '../Motion';
import type { FullHeroLayoutT } from './Hero';

export const FullHero = ({
  image,
  motion,
  theme,
  invertLayout,
  children,
  ...rest
}: FullHeroLayoutT) => {
  return (
    <Section
      theme={theme}
      justifyContent="center"
      position="relative"
      extend={() =>
        image?.src
          ? {
              backgroundImage: `url(${image.src})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }
          : {}
      }
      {...rest}
    >
      {motion && (
        <Box width="100%" height="100%" position="absolute" top="0" left="0">
          <Motion
            {...motion}
            extend={() => ({
              '> video': {
                objectFit: 'cover',
              },
            })}
          />
        </Box>
      )}
      <Narrow flexDirection="column" alignItems="center">
        {children}
      </Narrow>
    </Section>
  );
};
