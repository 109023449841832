export function WCQSimpleIcon({
  width = '24px',
  height = '24px',
  viewBox = '0 0 34 34',
}) {
  return (
    <svg
      aria-hidden="true"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8624 17.0001C24.2283 16.2751 25.1601 14.8318 25.1601 13.1689C25.1601 10.7782 23.2364 8.84009 20.8635 8.84009C19.1645 8.84009 17.6968 9.8336 17.0001 11.2749C16.3024 9.8336 14.8347 8.84009 13.1366 8.84009C10.7637 8.84009 8.84009 10.7782 8.84009 13.1689C8.84009 14.8328 9.77192 16.2761 11.1378 17.0001C9.77192 17.7251 8.84009 19.1684 8.84009 20.8312C8.84009 23.222 10.7637 25.1601 13.1366 25.1601C14.8356 25.1601 16.3034 24.1666 17.0001 22.7252C17.6968 24.1666 19.1655 25.1601 20.8635 25.1601C23.2364 25.1601 25.1601 23.222 25.1601 20.8312C25.1601 19.1674 24.2283 17.7241 22.8624 17.0001Z"
        fill="#FF403D"
      />
    </svg>
  );
}
export function BubbleIcon2({
  width = '24px',
  height = '24px',
  viewBox = '0 0 34 34',
}) {
  return (
    <svg
      aria-hidden="true"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3401 22.1C17.3401 24.8228 19.5473 27.03 22.2701 27.03H27.2001V22.1C27.2001 19.3773 24.9929 17.17 22.2701 17.17C19.5473 17.17 17.3401 19.3773 17.3401 22.1Z"
        fill="#FF80AB"
      />
      <path
        d="M19.04 12.92C19.04 16.3 16.3 19.04 12.92 19.04H6.80004V12.92C6.80004 9.54006 9.54006 6.80004 12.92 6.80004C16.3 6.80004 19.04 9.54006 19.04 12.92Z"
        fill="#FF80AB"
      />
    </svg>
  );
}
export function ArrowIcon({
  width = '24px',
  height = '24px',
  viewBox = '0 0 34 34',
}) {
  return (
    <svg
      aria-hidden="true"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9699 6.88501L18.1899 6.88501L18.1899 27.115L23.9699 27.115L23.9699 6.88501Z"
        fill="#196CFF"
      />
      <path
        d="M15.81 15.5549L10.03 15.5549L10.03 27.1149L15.81 27.1149L15.81 15.5549Z"
        fill="#196CFF"
      />
    </svg>
  );
}
export function RankingSimpleIcon({
  width = '24px',
  height = '24px',
  viewBox = '0 0 34 34',
}) {
  return (
    <svg
      aria-hidden="true"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5 8.33008H8.5V25.6701H14.11V19.8901H19.72V14.1101H25.5V8.33008Z"
        fill="#6E54BD"
      />
    </svg>
  );
}

export function LockIcon({
  width = '21px',
  height = '21px',
  viewBox = '0 0 24 24',
}) {
  return (
    <svg
      aria-hidden="true"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.375 10.5C2.89175 10.5 2.5 10.8918 2.5 11.375V17.5C2.5 17.9832 2.89175 18.375 3.375 18.375H15.625C16.1083 18.375 16.5 17.9832 16.5 17.5V11.375C16.5 10.8918 16.1083 10.5 15.625 10.5H3.375ZM0.75 11.375C0.75 9.92525 1.92525 8.75 3.375 8.75H15.625C17.0747 8.75 18.25 9.92525 18.25 11.375V17.5C18.25 18.9497 17.0747 20.125 15.625 20.125H3.375C1.92525 20.125 0.75 18.9497 0.75 17.5V11.375Z"
        fill="#161617"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2.625C8.57174 2.625 7.6815 2.99375 7.02513 3.65013C6.36875 4.3065 6 5.19674 6 6.125V9.625C6 10.1082 5.60825 10.5 5.125 10.5C4.64175 10.5 4.25 10.1082 4.25 9.625V6.125C4.25 4.73261 4.80312 3.39726 5.78769 2.41269C6.77226 1.42812 8.10761 0.875 9.5 0.875C10.8924 0.875 12.2277 1.42812 13.2123 2.41269C14.1969 3.39726 14.75 4.73261 14.75 6.125V9.625C14.75 10.1082 14.3582 10.5 13.875 10.5C13.3918 10.5 13 10.1082 13 9.625V6.125C13 5.19674 12.6313 4.3065 11.9749 3.65013C11.3185 2.99375 10.4283 2.625 9.5 2.625Z"
        fill="#161617"
      />
    </svg>
  );
}
export function KeyIcon({
  width = '21px',
  height = '21px',
  viewBox = '0 0 24 24',
}) {
  return (
    <svg
      aria-hidden="true"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.750244 14.7498V17.3748C0.750244 17.8998 1.10024 18.2498 1.62524 18.2498H5.12524V15.6248H7.75024V12.9998H9.50024L10.7252 11.7748C11.9414 12.1984 13.2652 12.1968 14.4803 11.7702C15.6954 11.3436 16.7297 10.5173 17.4141 9.42638C18.0984 8.33551 18.3923 7.04468 18.2477 5.76505C18.1031 4.48541 17.5285 3.29274 16.6179 2.38215C15.7073 1.47155 14.5146 0.896935 13.235 0.752302C11.9553 0.607669 10.6645 0.901583 9.57364 1.58596C8.48276 2.27034 7.65644 3.30466 7.22984 4.51973C6.80324 5.7348 6.80162 7.05867 7.22524 8.27477L0.750244 14.7498Z"
        stroke="black"
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4373 5.99976C13.6789 5.99976 13.8748 5.80388 13.8748 5.56226C13.8748 5.32063 13.6789 5.12476 13.4373 5.12476C13.1956 5.12476 12.9998 5.32063 12.9998 5.56226C12.9998 5.80388 13.1956 5.99976 13.4373 5.99976Z"
        fill="black"
        stroke="black"
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ProfileIcon({
  width = '21px',
  height = '21px',
  viewBox = '0 0 24 24',
}) {
  return (
    <svg
      aria-hidden="true"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5001 2.40625C9.17112 2.40625 8.0938 3.48356 8.0938 4.8125C8.0938 6.14144 9.17112 7.21875 10.5001 7.21875C11.829 7.21875 12.9063 6.14144 12.9063 4.8125C12.9063 3.48356 11.829 2.40625 10.5001 2.40625ZM6.56255 4.8125C6.56255 2.63788 8.32543 0.875 10.5001 0.875C12.6747 0.875 14.4376 2.63788 14.4376 4.8125C14.4376 6.98712 12.6747 8.75 10.5001 8.75C8.32543 8.75 6.56255 6.98712 6.56255 4.8125ZM10.5 12.0312C7.47974 12.0312 5.03127 14.4797 5.03125 17.5V17.7188H15.9688V17.5C15.9688 14.4797 13.5203 12.0312 10.5 12.0312ZM3.5 17.5C3.50002 13.634 6.63406 10.5 10.5 10.5C14.366 10.5 17.5 13.634 17.5 17.5V19.25H3.5V17.5Z"
        fill="black"
      />
    </svg>
  );
}

export function ControlIcon({
  width = '21px',
  height = '21px',
  viewBox = '0 0 24 24',
}) {
  return (
    <svg
      aria-hidden="true"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.45312 10.718C5.31562 11.0491 4.48438 12.0994 4.48438 13.3438C4.48438 14.5881 5.31562 15.6384 6.45312 15.9695L6.45313 18.375H7.98438V15.9695C9.12188 15.6384 9.95312 14.5881 9.95312 13.3438C9.95312 12.0994 9.12188 11.0491 7.98438 10.718V2.625H6.45312V10.718ZM13.0156 5.03051C11.8781 5.36163 11.0469 6.41187 11.0469 7.65625C11.0469 8.90063 11.8781 9.95087 13.0156 10.282V18.375H14.5469V10.282C15.6844 9.95087 16.5156 8.90063 16.5156 7.65625C16.5156 6.41187 15.6844 5.36163 14.5469 5.03051V2.625H13.0156V5.03051ZM13.7812 6.45312C13.1168 6.45312 12.5781 6.99178 12.5781 7.65625C12.5781 8.32072 13.1168 8.85938 13.7812 8.85938C14.4457 8.85938 14.9844 8.32072 14.9844 7.65625C14.9844 6.99178 14.4457 6.45312 13.7812 6.45312ZM7.21875 12.1406C6.55428 12.1406 6.01562 12.6793 6.01562 13.3438C6.01562 14.0082 6.55428 14.5469 7.21875 14.5469C7.88322 14.5469 8.42188 14.0082 8.42188 13.3438C8.42188 12.6793 7.88322 12.1406 7.21875 12.1406Z"
        fill="black"
      />
    </svg>
  );
}

export function CreateIconMobile({
  width = '30px',
  height = '30px',
  viewBox = '0 0 30 30',
}) {
  return (
    <svg
      aria-hidden="true"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0226 7.30286L8.40002 16.9259V21.7029H13.1775L22.8 12.0798L18.0226 7.30286Z"
        fill="#FF80AB"
      />
    </svg>
  );
}

export function AnalyzeIconMobile({
  width = '30px',
  height = '30px',
  viewBox = '0 0 30 30',
}) {
  return (
    <svg
      aria-hidden="true"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.15 6.41052L16.05 6.41052L16.05 24.2605L21.15 24.2605L21.15 6.41052Z"
        fill="#FFC738"
      />
      <path
        d="M13.95 14.0605L8.84998 14.0605L8.84998 24.2605L13.95 24.2605L13.95 14.0605Z"
        fill="#FFC738"
      />
    </svg>
  );
}

export function InteractIconMobile({
  width = '30px',
  height = '30px',
  viewBox = '0 0 30 30',
}) {
  return (
    <svg
      aria-hidden="true"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3 20.2192C15.3 22.6217 17.2475 24.5692 19.65 24.5692H24V20.2192C24 17.8168 22.0524 15.8692 19.65 15.8692C17.2475 15.8692 15.3 17.8168 15.3 20.2192Z"
        fill="#6E54BD"
      />
      <path
        d="M16.8 12.1193C16.8 15.1016 14.3823 17.5193 11.4 17.5193H5.99999V12.1193C5.99999 9.13695 8.41765 6.71929 11.4 6.71929C14.3823 6.71929 16.8 9.13695 16.8 12.1193Z"
        fill="#6E54BD"
      />
    </svg>
  );
}
