import { Section } from '../layout';
import { Motion } from '../Motion';
import type { FullHeroLayoutT } from './Hero';

export const FullCenterHero = ({
  image,
  motion,
  theme,
  invertLayout,
  children,
  ...rest
}: FullHeroLayoutT) => (
  <>
    {!invertLayout && (
      <Section theme={theme} {...rest} justifyContent="center">
        {children}
      </Section>
    )}
    {(image || motion) && (
      <>
        {image && (
          <Section
            backgroundImage={`url(${image.src})`}
            backgroundSize="cover"
            backgroundPosition="center"
            height={[175, 250, 350]}
          ></Section>
        )}
        {motion && (
          <Motion
            {...motion}
            height={[175, 250, 350]}
            width="100%"
            extend={() => ({
              '> video': {
                objectFit: 'cover',
              },
            })}
          />
        )}
      </>
    )}
    {invertLayout && (
      <Section theme={theme} {...rest} justifyContent="center">
        {children}
      </Section>
    )}
  </>
);
