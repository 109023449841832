'use client';
import FullColorBlock from '../FullColorBlock';
import CardsBlock from '../CardsBlock';
import { useHomepageData } from './useHomepageData';
import Hero from './Hero';
import EditorExperienceBlock from './EditorExperienceBlock';
import DesktopImageBlock from './DesktopImageBlock';
import HalfColorBlock from './HalfColorBlock';

export const Content = () => {
  const {
    heroData,
    desktopImageBlockData,
    cardsData,
    halfColorData,
    fullColorBlockData,
  } = useHomepageData();
  return (
    <>
      <Hero {...heroData} />
      <EditorExperienceBlock />
      <DesktopImageBlock {...desktopImageBlockData} />
      <CardsBlock {...cardsData} />
      {halfColorData.map((data, i) => (
        <HalfColorBlock key={data.info.title + i} {...data} />
      ))}
      <FullColorBlock {...fullColorBlockData} />
    </>
  );
};
