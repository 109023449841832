import { useTranslations } from '@mentimeter/i18n';
import {
  AnalyzeIconMobile,
  ArrowIcon,
  BubbleIcon2,
  CreateIconMobile,
  InteractIconMobile,
  RankingSimpleIcon,
  WCQSimpleIcon,
  ControlIcon,
  KeyIcon,
  LockIcon,
  ProfileIcon,
} from './Icons';

export const useHomepageData = () => {
  const t = useTranslations('common');

  const heroData = {
    title: t('home_page.hero.title'),
    subtitle: t('home_page.hero.subtitle'),
    cta: t('home_page.hero.cta'),
    cta_description: t('home_page.hero.cta_description'),
  };
  const desktopImageBlockData = {
    tag: t('home_page.desktop_image_block.tag'),
    title: t('home_page.desktop_image_block.title'),
    subtitle: t('home_page.desktop_image_block.subtitle'),
    slides: [
      {
        text: t('home_page.desktop_image_block.button_1'),
        icon: <WCQSimpleIcon />,
        desktop: {
          src: '//static.mentimeter.com/static/images/BreakTheIceBackground.png',
          alt: t('home_page.desktop_image_block.button_1'),
        },
        mobile: {
          src: '//static.mentimeter.com/static/images/BreakTheIcePhone.png',
          alt: t('home_page.desktop_image_block.button_1'),
        },
      },
      {
        text: t('home_page.desktop_image_block.button_2'),
        icon: <BubbleIcon2 />,
        desktop: {
          src: '//static.mentimeter.com/static/images/GetInstantFeedbackBackground.png',
          alt: t('home_page.desktop_image_block.button_2'),
        },
        mobile: {
          src: '//static.mentimeter.com/static/images/GetInstantFeedbackPhone.png',
          alt: t('home_page.desktop_image_block.button_2'),
        },
      },
      {
        text: t('home_page.desktop_image_block.button_3'),
        icon: <ArrowIcon />,
        desktop: {
          src: '//static.mentimeter.com/static/images/EvaluateFeedbackBackground.png',
          alt: t('home_page.desktop_image_block.button_3'),
        },
        mobile: {
          src: '//static.mentimeter.com/static/images/EvaluateFeedbackPhone.png',
          alt: t('home_page.desktop_image_block.button_3'),
        },
      },
      {
        text: t('home_page.desktop_image_block.button_4'),
        icon: <RankingSimpleIcon />,
        desktop: {
          src: '//static.mentimeter.com/static/images/MakeDecisionsBackground.png',
          alt: t('home_page.desktop_image_block.button_4'),
        },
        mobile: {
          src: '//static.mentimeter.com/static/images/MakeDecisionsPhone.png',
          alt: t('home_page.desktop_image_block.button_4'),
        },
      },
    ],
  };
  const cardsData = {
    tag: t('home_page.cards_block.tag'),
    title: t('home_page.cards_block.title'),
    subtitle: t('home_page.cards_block.subtitle'),
    cards: [
      {
        text: {
          title: t('home_page.cards_block.card_1.title'),
          subtitle: t('home_page.cards_block.card_1.subtitle'),
          mobileIcon: <CreateIconMobile />,
        },
        image: {
          src: '//static.mentimeter.com/static/images/HowItWorksCreate.png',
          alt: t('home_page.cards_block.card_1.alt'),
        },
      },
      {
        text: {
          title: t('home_page.cards_block.card_2.title'),
          subtitle: t('home_page.cards_block.card_2.subtitle'),
          mobileIcon: <AnalyzeIconMobile />,
        },
        image: {
          src: '//static.mentimeter.com/static/images/HowItWorksInteract.png',
          alt: t('home_page.cards_block.card_2.alt'),
        },
      },
      {
        text: {
          title: t('home_page.cards_block.card_3.title'),
          subtitle: t('home_page.cards_block.card_3.subtitle'),
          mobileIcon: <InteractIconMobile />,
        },
        image: {
          src: '//static.mentimeter.com/static/images/HowItWorksAnalyze.png',
          alt: t('home_page.cards_block.card_3.alt'),
        },
      },
    ],
  };
  const halfColorData = [
    {
      image: {
        src: '//static.mentimeter.com/static/images/ClassroomBackground.webp',
        alt: t('home_page.half_color_block_1.alt'),
      },
      reverse: false,
      info: {
        tag: t('home_page.half_color_block_1.tag'),
        title: t('home_page.half_color_block_1.text'),
        author: {
          name: 'Teresa Delfin',
          role: 'California State Polytechnic University',
        },
        cta: {
          text: t('home_page.half_color_block_1.button'),
          url: '/education',
        },
      },
    },
    {
      image: {
        src: '//static.mentimeter.com/static/images/OfficeBackground.png',
        alt: t('home_page.half_color_block_2.alt'),
      },
      reverse: true,
      info: {
        tag: t('home_page.half_color_block_2.tag'),
        title: t('home_page.half_color_block_2.text'),
        author: {
          name: 'Merv Wyeth',
          role: 'Program Director at Project Management Institute',
        },
        cta: {
          text: t('home_page.half_color_block_2.button'),
          url: '/work',
        },
      },
    },
  ];
  const fullColorBlockData = {
    strong: t('home_page.full_color_block.strong'),
    title: t('home_page.full_color_block.title'),
    subtitle: t('home_page.full_color_block.subtitle'),
    cta: t('home_page.full_color_block.button'),
    bullets: [
      { text: t('home_page.full_color_block.bullet_1'), icon: <LockIcon /> },
      { text: t('home_page.full_color_block.bullet_2'), icon: <KeyIcon /> },
      { text: t('home_page.full_color_block.bullet_3'), icon: <ProfileIcon /> },
      { text: t('home_page.full_color_block.bullet_4'), icon: <ControlIcon /> },
    ],
  };
  return {
    heroData,
    fullColorBlockData,
    halfColorData,
    cardsData,
    desktopImageBlockData,
  };
};
