import React from 'react';
import { Section } from 'src/ui/layout';
import { Motion } from 'src/ui/Motion';
import { Box } from '@mentimeter/ragnar-ui';
import { purpleLight200, whiteBase } from '@mentimeter/ragnar-colors';

const EditorExperienceBlock = () => {
  return (
    <Section px="space0" py="space4">
      <Box justifyContent="center" alignItems="center" width="100%">
        <Box
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          backgroundImage="url(https://static.mentimeter.com/static/images/BKGIMAGENU.svg)"
          backgroundSize="cover"
          backgroundPosition="center"
          mt="space8"
        >
          <Box flex="1 1 100%" mx={['space8', null, 'space0']} bg="bg">
            <Motion
              borderColor={purpleLight200}
              borderWidth="6px"
              borderStyle="solid"
              borderRadius="16px"
              src="https://static.mentimeter.com/static/motion/Square.mp4"
              loop
              disableRemotePlayback
              height={['auto', 'auto', 600]}
              extend={() => ({
                '> video': {
                  maxWidth: '1030px',
                  borderRadius: '10px',
                  mb: '-24px',
                },
              })}
            />
          </Box>
        </Box>
      </Box>
      <Box
        position="absolute"
        bottom="0"
        width="100%"
        height="60px"
        zIndex={2}
        bg={whiteBase}
      />
    </Section>
  );
};

export default EditorExperienceBlock;
