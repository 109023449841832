import { Box } from '@mentimeter/ragnar-ui';
import { DecoratedNextImage } from 'src/components/image/DecoratedNextImage';
import { Narrow, Section } from '../layout';
import { Motion } from '../Motion';
import type { HeroLayoutT } from './Hero';

export const CenterHero = ({
  image,
  motion,
  theme,
  invertLayout,
  imageMaxWidth,
  children,
  ...rest
}: HeroLayoutT) => (
  <Section theme={theme} justifyContent="center" {...rest}>
    <Narrow flexDirection="column" alignItems="center">
      {!invertLayout && children}
      {(image || motion) && (
        <Box
          width={1}
          maxWidth={imageMaxWidth}
          mt={!invertLayout ? 'space8' : 'space0'}
          mb={invertLayout ? 'space8' : 'space0'}
          alignItems="center"
        >
          {image && <DecoratedNextImage {...image} />}
          {motion && <Motion {...motion} />}
        </Box>
      )}
      {invertLayout && children}
    </Narrow>
  </Section>
);
